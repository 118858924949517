@use '@angular/material' as mat;
@include mat.core(); // Ensure core styles are included

$custom-primary-palette: (
  50: var(--primary-color),
  100: var(--primary-color),
  200: var(--primary-color),
  300: var(--primary-color),
  400: var(--primary-color),
  500: var(--primary-color),
  600: var(--primary-color),
  700: var(--primary-color),
  800: var(--primary-color),
  900: var(--primary-color),
  contrast: (
    50: var(--gray1000),
    100: var(--gray600),
    200: var(--gray500),
    300: var(--gray400),
    400: var(--gray300),
    500: var(--gray200),
    600: var(--gray100),
    700: var(--gray000),
    800: var(--gray000),
    900: var(--gray000),
  ),
);

$my-primary: mat.define-palette($custom-primary-palette);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

.mdc-notched-outline__notch {
  border-right: none;
}

.mdc-tooltip__surface {
  background-color: var(--gray1000) !important;
  color: var(--gray100) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 4px !important;
  border-color: var(--gray200) !important;
  padding: 12px !important;
  font-size: 1rem /* 16px */ !important;
  line-height: 1.5rem /* 24px */ !important;
  overflow: initial !important;
  &:after {
    width: 0 !important;
    height: 0 !important;
    content: '' !important;
    position: absolute !important;
    border-left: 0.5rem solid transparent !important;
    border-right: 0.5rem solid transparent !important;
    border-bottom: 0.5rem solid var(--gray1000) !important;
    top: -0.5rem !important;
    right: calc(50% - 0.5rem) !important;
    transform: rotate(0) !important;
  }
}

@include mat.all-component-themes($my-theme);
