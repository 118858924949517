/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './theme.scss';

@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MYRIADPRO-REGULAR.OTF') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MYRIADPRO-SEMIBOLD.OTF') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MyriadPro-Light.otf') format('opentype');
  font-weight: 300;
}

:root {
  --primary-color: #009456;
  --gray000: #ffffff;
  --gray100: #fafaf9;
  --gray200: #e2e8f0;
  --gray300: #cacbce;
  --gray400: #8e8e8a;
  --gray500: #5b6675;
  --gray600: #334155;
  --gray1000: #0f2400;
  --warning: #e35740;
  --error: #dc291a;
  --fenacoGreen: #009456;
  --appBgrd: #ededea;
}

body {
  font-family: 'Myriad Pro', sans-serif;
  color: var(--gray600);
}

.error-snackbar {
  --mdc-snackbar-container-color: red;
  --mdc-snackbar-supporting-text-color: white;
}

.success-snackbar {
  --mdc-snackbar-container-color: rgb(196, 255, 173);
  --mdc-snackbar-supporting-text-color: rgb(91, 91, 91);
}

.info-snackbar {
  --mdc-snackbar-container-color: rgb(170, 222, 255);
  --mdc-snackbar-supporting-text-color: rgb(0, 0, 0);
}

.pageHeader {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: var(--primary-color);
  width: 100%;
  text-align: left;
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
}

.pageParagraph {
  --tw-text-opacity: 1;
  color: var(--primary-color);
  text-justify: newspaper;
  text-align: left;
  width: 100%;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.pageLink {
  color: var(--primary-color);
  text-decoration: underline;
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 600;
}

.sub-section-container {
  width: 100%;
  padding: 0 2rem;
  margin-left: auto;
  margin-right: auto;
}

.text-content-container {
  max-width: 48rem /* 768px */;
}

.text-content-container > ul {
  list-style: '\2013';
  list-style-position: inside;
}

.text-content-container > ul > li::marker {
  font-weight: bold;
}

.text-content-container > ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.text-content-container > ol > li::marker {
  font-weight: bold;
}

.text-content-container > *:not(:last-child) {
  padding-bottom: 2rem;
}

.sub-section-header {
  font-size: 1.5rem /* 24px */;
  line-height: 2rem /* 32px */;
  font-weight: 600;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(0 148 86 / var(--tw-text-opacity));
}

.sub-section-desc {
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 300;
  text-align: center;
  text-justify: newspaper;
  max-width: 572px;
  --tw-text-opacity: 1;
  color: rgb(0 148 86 / var(--tw-text-opacity));
}

.filled-button {
  background-color: var(--primary-color);
  color: white;
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 700;
  padding: 12px 19px 12px 19px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  width: fit-content;
}

.filled-button:disabled {
  background-color: var(--gray200);
  color: var(--gray500);
  cursor: not-allowed;
}

.outline-button {
  background-color: white;
  color: #009456;
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 700;
  padding: 12px 19px 12px 19px;
  border: 2px solid;
  border-color: var(--primary-color);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  width: fit-content;
}

.outline-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.scrolled-down {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: fit-content;
  position: fixed;
  z-index: 1000;
}

.content-max-width {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .changeFormContainer {
    min-width: 420px;
  }
}

@media (min-width: 768px) {
  .pageHeader {
    font-size: 4rem;
    line-height: 4.75rem;
  }
  .pageParagraph {
    font-size: 2rem;
    line-height: 2.66rem;
  }
  .pageLink {
    font-size: 2rem;
    line-height: 2.66rem;
  }
  .sub-section-container {
    max-width: 1280px;
  }
  .sub-section-header {
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-align: left;
  }
  .sub-section-desc {
    font-size: 2rem;
    line-height: 2.66rem;
    text-align: left;
  }
}
